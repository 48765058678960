import React from 'react';

import GlobalStyles from '../global/styles';
import SEO from "../components/seo";
import ReactPlayer from 'react-player';

import Biplan_Video from '../images/Home/Biplan_Video.mp4';

function pages() {
  return(
      <>
        <GlobalStyles />
        <SEO title="Contato" />

        <ReactPlayer
            controls
            width="100%"
            height="100vh"
            url={Biplan_Video}
        />
      </>
  );
}

export default pages;